// import libraries
import { createApp } from 'vue'
import Vuex from "vuex"
import VuexPersistence from 'vuex-persist'
import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css"
import '../scss/main.scss'; // custom css

//import views
import BlankLayout from './layouts/BlankLayout.vue'
import AppLayout from './layouts/AppLayout.vue'

// import page components
import App from './App.vue'
import Dashboard from './components/Dashboard.vue'
import Properties from './components/Properties.vue'
import Vehicles from './components/Vehicles.vue'
import Salaries from './components/Salaries.vue'
import Expenses from './components/Expenses.vue'
import Cash from './components/Cash.vue'
import Loans from './components/Loans.vue'
import BrokerTxns from './components/BrokerAccounts.vue'
import SecuritiesSummary from './components/SecuritiesSummary.vue'
import SecurityPosition from './components/SecurityPosition.vue'
import Login from './components/Login.vue'
import Homepage from './components/Homepage.vue'
import BankAccountSummary from './components/BankAccountSummary.vue'
import BankAccountTxns from './components/BankAccountTxns.vue'
import SuperFunds from './components/SuperFunds.vue'

// Vuex Store stuff

const store = new Vuex.Store({
    state: {
        user: null,
        token: null
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            console.log('state.user: ' + state.user.user_name);
        },
        setToken(state, token) {
            state.token = token;
            console.log('state.token: ' + state.token);
        },
        destroyToken(state){
            state.token = null;
        }
    },
    actions: {},
    getters: {
        isLoggedIn(state) {
            return !!state.token;
        },
    },
    plugins: [new VuexPersistence().plugin] // Using local browser storage which is not best practice (insecure)
});

// Vuetify stuff

const vuetify = createVuetify({
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
          mdi,
        },
      },
    components,
    directives,
    theme: {
        defaultTheme: 'light',
        //
    },
  })

// Axios global handling

axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        if (error.response.status === 403) {
          // Redirect to login page
          console.log("got a 403");
          router.push('/login');
        } else {
          // Show a generic error message
          console.log("An error occured: " + error);
        }
      }
      return Promise.reject(error)
    },
  );
  
  axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL; 

// Set Vue Router //

  const routes = [
    { 
        path: '/dashboard',
        meta: { layout: AppLayout }, 
        component: Dashboard 
    },
    { 
        path: '/properties',
        meta: { layout: AppLayout }, 
        component: Properties 
    },
    { 
        path: '/vehicles',
        meta: { layout: AppLayout }, 
        component: Vehicles 
    },
    { 
        path: '/salaries',
        meta: { layout: AppLayout }, 
        component: Salaries 
    },
    { 
      path: '/expenses',
      meta: { layout: AppLayout }, 
      component: Expenses
  },
    { 
        path: '/cash',
        meta: { layout: AppLayout }, 
        component: Cash 
    },
    { 
        path: '/loans', 
        meta: { layout: AppLayout },
        component: Loans 
    },
    { 
      path: '/superfunds', 
      meta: { layout: AppLayout },
      component: SuperFunds 
    },
    { 
        path: '/brokeraccounts/:id', 
        meta: { layout: AppLayout },
        component: BrokerTxns,
        props: true 
    },
    { 
      path: '/brokeraccounts/:brokerid/position/:ticker', 
      meta: { layout: AppLayout },
      component: SecurityPosition,
      props: true 
  },
  { 
    path: '/securitiessummary', 
    meta: { layout: AppLayout },
    component: SecuritiesSummary 
  },
  { 
    path: '/bankaccountsummary', 
    meta: { layout: AppLayout },
    component: BankAccountSummary 
  },
  { 
    path: '/bankaccounttxns/:id', 
    meta: { layout: AppLayout },
    component: BankAccountTxns 
  },
  { 
      path: '/login',
      meta: { layout: BlankLayout }, 
      component: Login 
  },
  { 
    path: '/',
    meta: { layout: BlankLayout }, 
    component: Homepage 
}
  ];

const router = createRouter({
    history: createWebHistory(),
    routes
});

/////////////////////


const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);

app.mount('#app');


