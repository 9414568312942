<template>
  <v-dialog v-model="showModal" width="400" @click:outside=closeDialog>
      <v-card max-width="400">
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h5 text-medium-emphasis ps-2 title-text" >edit broker txn</div>
          <v-btn icon="mdi-close" variant="text" @click=closeDialog></v-btn>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-sheet class="mx-auto" width="300">
            <v-form @submit.prevent>
              <v-text-field label="id" variant="underlined" class="form_field" v-model="this.currentRow.id" disabled></v-text-field>
              <v-text-field label="txn date" variant="underlined" class="form_field" v-model="this.txn_date_copy" type="date"></v-text-field>
              <v-text-field label="ticker" variant="underlined" class="form_field" v-model="this.currentRow.ticker"></v-text-field>
              <v-text-field label="txn type" variant="underlined" class="form_field" v-model="this.currentRow.txn_type"></v-text-field>
              <v-text-field label="qty" variant="underlined" class="form_field" v-model="this.currentRow.qty"></v-text-field>
              <v-text-field label="price" variant="underlined" class="form_field" v-model="this.currentRow.price"></v-text-field>
              <v-text-field label="brokerage" variant="underlined" class="form_field" v-model="this.currentRow.brokerage"></v-text-field>
              <v-text-field label="currency" variant="underlined" class="form_field" v-model="this.currentRow.currency"></v-text-field>
              <v-text-field v-if="this.currentRow.currency == 'USD' || this.currentRow.currency == 'SGD'" label="spot fx" variant="underlined" class="form_field" v-model="this.currentRow.base_currency_spot_rate"></v-text-field>
            </v-form>
          </v-sheet>
        </v-card-text>
        <template v-slot:actions>
          <v-row align="center" justify="end" class="actionsRow">
            <v-btn v-on:click="submitEditRecord" class="mt-2 submit_button" type="submit" block>update</v-btn>
            <v-btn v-on:click="submitDeleteRecord" class="mt-2 submit_button" type="submit" block>delete</v-btn>
          </v-row>
        </template>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  props: {
    show: Boolean,
    currentRow: Object,
  },
  data() {
    return {
      txn_date_formatted: '', //yyyy-mm-dd


      
    }
  },
  computed: {
    ...mapState({
      token: "token"
    }),
    showModal: {
      get () {
        return this.show
      }
    },
  },
  methods: {  
    async submitDeleteRecord(e) {      
      try {
        e.preventDefault();
        const { data } = await axios.delete('/securities/txns/' + this.currentRow.id,
          {
            headers: {
              Authorization: `Bearer ${this.token}`
          }
        })
        .then((response) => {
          console.log(response);
          this.$parent.getBrokerTxns(); // update txn list on local
          this.$parent.getOpenPositionData(); // update open position data on local
          this.$emit("close"); // close modal via parent
        });

      } catch (error) {
        console.log(error);
      }
    },
    async submitEditRecord(e) {
      try {
        e.preventDefault();
        const { data } = await axios.request({
          url: "/securities/txns/" + this.currentRow.id,
          method: "PUT",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
          data: {
            txn_date: this.txn_date_copy,
            ticker: this.currentRow.ticker,
            txn_type: this.currentRow.txn_type,
            qty: this.currentRow.qty,
            currency: this.currentRow.currency,
            price: this.currentRow.price,
            base_currency_spot_rate: this.currentRow.base_currency_spot_rate,
            brokerage: this.currentRow.brokerage
          }
        })
        .then((response) => {
          // this.resetDataValues(); // clear form inputs
          this.$parent.getBrokerTxns(); // update txn list on local
          this.$parent.getOpenPositionData(); // update open position data on local
          this.$emit("close"); // close modal via parent
        });
      } catch (error) {
        console.log(error);
      }
    },
    closeDialog(){
      // this.resetDataValues();
      this.$parent.getBrokerTxns();
      this.$emit('close');
    },
  },
  watch: {
    currentRow(newVal) {
      this.txn_date_copy = moment(newVal.txn_date).format('YYYY-MM-DD');
      
    }
  } 
}
</script>

<style scoped>
.title-text, .body-text, .form_field, .submit_button {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.actionsRow{
  margin-bottom: 5px;
}

</style>