<template>
<v-dialog v-model="showModal" width="400" @click:outside=closeDialog>
      <v-card max-width="400">
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h5 text-medium-emphasis ps-2 title-text" >edit bank account</div>
          <v-btn icon="mdi-close" variant="text" @click=closeDialog></v-btn>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-sheet class="mx-auto" width="300">
            <v-form @submit.prevent>
              <v-text-field label="id" variant="underlined" class="form_field" v-model="this.bankAccount.id" disabled></v-text-field>
              <v-text-field label="bank name" variant="underlined" class="form_field" v-model="this.bankAccount.bank_name"></v-text-field>
              <v-text-field label="account name" variant="underlined" class="form_field" v-model="this.bankAccount.account_name"></v-text-field>
              <v-text-field label="account nickname" variant="underlined" class="form_field" v-model="this.bankAccount.account_nickname"></v-text-field>
              <v-text-field label="bsb" variant="underlined" class="form_field" v-model="this.bankAccount.bsb"></v-text-field>
              <v-text-field label="account number" variant="underlined" class="form_field" v-model="this.bankAccount.account_number"></v-text-field>
              <v-text-field label="starting txn import date" variant="underlined" class="form_field" v-model="this.importDateCopy" type="date"></v-text-field>
            </v-form>
          </v-sheet>
        </v-card-text>
        <template v-slot:actions>
          <v-row align="center" justify="end" class="actionsRow">
            <v-btn v-on:click="submitForm" class="mt-2 submit_button" type="submit" block>update</v-btn>
            <v-btn v-on:click="submitDeleteRecord" class="mt-2 submit_button" type="submit" block>delete</v-btn>
          </v-row>
        </template>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  props: {
    show: Boolean,
    bankAccount: Object,
    importStartingDate: String
  },
  data() {
    return {
      importDateCopy: String
      
    }
  },
  computed: {
    ...mapState({
      token: "token"
    }),
    showModal: {
      get () {
        return this.show
      },
    },
  },
  methods: {  
    async submitForm(e) {
      try {
        e.preventDefault();
        const { data } = await axios.request({
          url: "/bank/accounts/" + this.bankAccount.id,
          method: "PUT",
          headers: {
              Authorization: `Bearer ${this.token}`
          },
          data: {
            bank_name: this.bankAccount.bank_name,
            account_name: this.bankAccount.account_name,
            account_nickname: this.bankAccount.account_nickname,
            bsb: this.bankAccount.bsb,
            account_number: this.bankAccount.account_number,
            starting_txn_import_date: this.importDateCopy,
          }
        })
        .then((response) => {
          this.$parent.getBankAccountsSummaryData(); // update list on local
          this.$emit("close"); // close modal via parent
        });
      } catch (error) {
        console.log(error);
      }
    },
    async submitDeleteRecord(e) {      
      try {
        e.preventDefault();
        const { data } = await axios.delete('/bank/accounts/' + this.currentRow.id,
          {
            headers: {
              Authorization: `Bearer ${this.token}`
          }
        })
        .then((response) => {
          // console.log(response);
          this.$parent.getBankAccountsSummaryData(); // update list on local
          this.$emit("close"); // close modal via parent
        });

      } catch (error) {
        console.log(error);
      }
    },
    closeDialog(){
      this.$parent.getBankAccountsSummaryData();
      this.$emit('close');
    } 

  },
  watch: {
    importStartingDate(newVal) {
      this.importDateCopy = newVal;
      console.log(this.importDateCopy);
    }
  }
  
}
</script>

<style scoped>

.title-text, .body-text, .form_field, .submit_button {
  font-family: 'Courier New', Courier, monospace, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.actionsRow{
  margin-bottom: 5px;
}

</style>